<template>
<Pcenheader></Pcenheader>
<div class="enarticlebox">
    <div class="artheader">
        <div class="headerimg"><img :src="data.baseUrl+'/'+data.enImg" alt=""></div>
        <div class="bottomback">
            <div class="centerback"><div class="lfettitle" ref="lfettitle">{{data.title_en}}</div>
                <div class="righttext" ref="righttext">
                    <span @click="router.push('/en')">HOME</span>
                    <span v-for="item in data.idPrenArr" :key="item.id">{{'\n > \n' + item.title_en}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="section">
        <div>
            <div class="leftNavlist" ref="leftNavlist">
                <nav @click="leftnavHeaght(i,item)" v-for="(item,i) in data.leftNavs" :key="item.id">
                    <em></em>
                    <span>{{item.title_en}}</span>
                    <i v-show="item.children"></i>
                    <div class="towmenus" v-if="item.children">
                        <span id="section" v-for="(item2,i2) in item.children" :key="item2.id" @click.stop="setleftTwo(i2,item2,i,item)">{{item2.title_en}}</span>
                    </div>
                </nav>
            </div>
        </div>
        <div class="rightRouterview" ref="rightRouterview">
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component :is="Component"  v-if="$route.meta.keepAlive"/>
                </keep-alive>
                <component :is="Component"  v-if="!$route.meta.keepAlive"/>
            </router-view>
        </div>
    </div>
    <!-- <Pcenfooter></Pcenfooter>  -->
    <component :is='data.footers' :en="true"></component>
</div>
</template>

<script setup>
import Pcenheader from '@/components/englishCom/endheader.vue'
import Pcenfooter from '@/components/englishCom/enfooter.vue'
import Phonefoot from '@/components/footerPhone.vue'
import { ref, onMounted, watch,onUnmounted, nextTick, reactive } from 'vue'
import miiter from '@/utils/miitbus.js'
import * as http from '@/api/API.js'
import { useRouter,useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
const data = reactive({
    baseUrl: process.env.VUE_APP_BASE_URL,
    enImg:'',
    leftNavs:[],
    title_en:'',
    idPrenArr:[],
    homenavs:[
            {title_en:'News',id:318,tid:318,level:1,vue_url:'Enarticlelist'},
            {title_en:'Seminars',id:322,tid:322,level:1,vue_url:'Enarticlelist'},
            {title_en:'Conferences',id:323,tid:323,level:1,vue_url:'Enarticlelist'},
            {title_en:'Announcements',id:321,tid:321,level:1,vue_url:'Enarticlelist'},
            {title_en:'video',id:359,tid:359,level:1,vue_url:'Envideo'},
    ],
    footers:''
})
// 需要做动画的dom
const lfettitle = ref(null)
const righttext = ref(null)
const leftNavlist = ref(null)
const rightRouterview = ref(null)

onMounted(() => {
    animate(lfettitle.value,100)
    animate(righttext.value,300)
    animate(leftNavlist.value,250)
    animate(rightRouterview.value,350)
    // 全局事件总线，动态修改二级页面
    // 判断窗口大于750
    const getphoneWidht = () => {
        let widthWind = window.innerWidth
        sizeWidth()
        window.addEventListener("resize", () => {
            widthWind = window.innerWidth
            sizeWidth()
        })
        function sizeWidth() {
            if (widthWind < 750) {
                data.footers = Phonefoot
            } else {
                data.footers = Pcenfooter
            }
        }
    }
    getphoneWidht()
})
// 模拟进场动画函数，动画进场
const animate = (dom1,time) => {
    setTimeout(() => {
        dom1.style['transform'] = 'translateY(0)'
        dom1.style['opacity'] = '1'
    },time)
}
// 动画出场
const animateStr = (dom1,time) => {
    setTimeout(() => {
        dom1.style['opacity'] = '0'
        dom1.style['transform'] = 'translateY(800px)'
    },time)
}
// 有三级，直接跳转三级
const menu3 = (ee) => {
  router.push({
      name: ee.vue_url,
      params:{
      id:ee.id,
      tid:ee.tid,
      level:ee.level
      }
    })
}
// 点击一级菜单跳转对应页面
const leftnavHeaght = (e,item) => {
    setleftscri(e)
     // 如果有外部链接就跳转
  if(item.external_url){
    window.open(item.external_url)
    return
  }
  if(item.template_type == 1){
    router.push({
      name: item.children[0].vue_url,
      params:{
      id:item.children[0].id,
      tid:item.children[0].tid,
      level:item.children[0].level
      }
    })
    miiter.emit('onEnactilcetap')
    return
  }
    router.push({
        name: item.vue_url,
        params:{
          id:item.id,
          tid:item.tid,
          level:item.level
        }
    })
    miiter.emit('onEnactilcetap',item.tid)
}
// 点击左侧二级菜单
const setleftTwo = (i2,item2,i,item) => {
    router.push({
        name: item2.vue_url,
        params:{
          id:item2.id,
          tid:item2.tid,
          level:item2.level
        }
    })
    miiter.emit('onEnactilcetap')
}

// 点击左侧一级菜单设置样式
const setleftscri = (e,i3) => {
    // e == -1 ?e = 0 : e = e
    const ul = leftNavlist.value?leftNavlist.value.children:[] // 拿到当前ul元素
    const li = ul[e]// 拿到当前li元素
    const liChildren = li?li.children:[] // 拿到子级
    const towmenusHeight = liChildren[3]&&liChildren[3].offsetHeight // 左侧三级级元素的高度
    const liHeight = 71 // 每个li本身高度
    const lisetBottom = 10  // 动态设置高度时，额外加10px
    // 设置样式之前，恢复当前样式
    for(let i = 0; i < ul.length; i ++){
        setStyle(ul[i].children[0],'opacity',0)
        setStyle(ul[i].children[1],'color','#6e6e6e')
        setStyle(ul[i],'height',liHeight/128+'rem')
        setStyle(ul[i].children[2],'transform','rotate(0)')
        ul[i].children[3]&&setStyle(ul[i].children[3],'opacity',0)
        ul[i].children[3]&&setStyle(ul[i].children[3],'visibility','hidden')
    }
    // 设置样式
    setStyle(liChildren[0],'opacity',1)
    setStyle(liChildren[1],'color','#174994')
    if(!liChildren[3])return
    setStyle(li,'height',(towmenusHeight+liHeight+lisetBottom+20)/128+'rem')
    // setStyle(li,'height',(towmenusHeight+liHeight-20)+'px')
    setStyle(liChildren[2],'transform','rotate(90deg)')
    setStyle(liChildren[3],'visibility','visible')
    setStyle(liChildren[3],'opacity','1')
    // 如果有三级菜单，就有高亮操作
    if(!i3&&i3<0)return
    // 设置样式之前，恢复当前样式
    for(let i = 0; i < liChildren[3].children.length; i ++){
        setStyle(liChildren[3].children[i],'color','#6e6e6e')
    }
    setStyle(liChildren[3].children[i3],'color','#174994')
}
// 设置样式公用方法
const setStyle = (dom,styles,value) => {
    dom?dom.style[styles] = value:''
}

const getLeftnavbackg = (tids) => {
    const obj = { id:tids?tids:route.params.tid }
    // 首页模块的id数组
    const homeIds = ['318','321','322','323','359',318,321,322,323,359]
    http.getBannernav_list(obj).then(res => {
        if(res.data.code == 200){
            data.enImg = res.data.data[0].url
            data.title_en = res.data.data[0].title_en
            data.leftNavs = res.data.data[0].children
            // 如果没有下级且是首页模块点进来，默认左侧导航为首页模块
            if(!data.leftNavs){
                //如果是点击首页more进来，左侧导航为首页模块
                if(homeIds.includes(obj.id)){
                    data.leftNavs = data.homenavs
                    const ind = data.homenavs.findIndex(x => x.id == route.params.id)
                    nextTick(() => { setleftscri(ind) })
                }
                data.idPrenArr = treeFindPath(res.data.data,(r) => r.id == route.params.id)
                animate(rightRouterview.value,350)
                return
            }
            // 根据id获取上级所有数据
            data.idPrenArr = treeFindPath(res.data.data,(r) => r.id == route.params.id)
            // 利用id查找对应的索引，给与左侧点亮
            if(route.params.level == 3){    
                // 找出上级父级数据
                const levelTwo = data.idPrenArr.find(item => item.level == 2)
                // 根据id找二级索引
                const index =  data.leftNavs.findIndex(item => item.id == levelTwo.id)
                // 根据id找三级索引
                const index3 = levelTwo.children.findIndex(x => x.id == route.params.id)
                data.index3 = index3
                nextTick(() => { setleftscri(index,index3) })
            }else{
                const index =  data.leftNavs.findIndex(item => item.id == route.params.id)
                nextTick(() => { setleftscri(index) })
            }
            animate(rightRouterview.value,350)
        }
    })
}
getLeftnavbackg(route.params.tid)

// 查找父节点方法
function treeFindPath (tree, func, path = []) {
        if (!tree) return []
        for (const item of tree) {
          // 这里按照你的需求来存放最后返回的内容吧
          path.push(item)
          if (func(item)) return path
          if (item.children) {
            const findChildren = treeFindPath(item.children, func, path)
            if (findChildren.length) return findChildren
          }
          path.pop()
        }
        return []
}

miiter.on('onEnactilcetap',(e) => {
    setTimeout(() => animateStr(rightRouterview.value,0))
    setTimeout(() => { getLeftnavbackg(e) },200)
})

// 页面卸载清除事件总线
onUnmounted(() => {
    miiter.off('onEnactilcetap')
})
</script>

<style lang="less" scoped>
@maxw: 128rem;
.enarticlebox{
    margin: 0 auto;
    overflow: hidden;
    width: 1920/@maxw;
    .artheader{
        position: relative;
        width: 100%;
        height: 729/@maxw;
        background-color: #174994;
        .headerimg{
            width: 100%;
            height: 100%;
            img{width: 100%;height: 100%;vertical-align: middle;object-fit: cover;}
        }
        .bottomback{
            width: 100%;
            height: 76/@maxw;
            position: absolute;
            bottom: 0;
            margin: 0;
            background: url("../../../assets/enarticleback.png");
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            font-family: 'Microsoft YaHei';
            .centerback{
                width: 1778/@maxw;
                height: 100%;
                background:linear-gradient(150deg, #DDCEC0 50%, #B19A86 100%);
                display: flex;
                justify-content:space-between;
                align-items: center;
                overflow: hidden;
                .lfettitle{
                    width: 382/@maxw;
                    height: 100%;
                    background: url("../../../assets/enarticleleftback.png");
                    background-size: 100% 100%;
                    font-size: 30/@maxw;
                    font-weight: bold;
                    color: #fff;
                    font-family: Georgia;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    transform: translateY(76px);
                    opacity: 0;
                    transition: ease .8s;
                }
                .righttext{
                    // cursor: pointer;
                    color: #174994;
                    font-size: 19/@maxw;
                    margin-right: 46/@maxw;
                    transform: translateY(76px);
                    opacity: 0;
                    transition: ease .8s;
                    font-family: 'Microsoft YaHei';
                }       
            }
        }
    }
    .section{
        overflow: hidden;
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;
        padding: 0 70/@maxw 295/@maxw 71/@maxw;
        display: flex;
        justify-content: space-between;
        .leftNavlist{
            position: relative;
            width: 382/@maxw;
            min-height: 443/@maxw;
            background:linear-gradient(150deg, #F0F0F0 0%,#E9E9E9 30% ,#C6C6C6 100%);
            transform: translateY(800px);
            opacity: 0;
            transition: ease .8s;
            box-sizing: border-box;
            padding: 5/@maxw 28/@maxw 20/@maxw 45/@maxw;
            nav{
                cursor: pointer;
                position: relative;
                width: 100%;
                height: 71/@maxw;
                background-image: url("../../../assets/zhixian_.png");
                background-position:bottom;
                background-repeat: no-repeat;
                line-height: 71/@maxw;
                color: #6e6e6e;
                font-size: 22/@maxw;
                font-family: Georgia;
                transition: ease .3s;
                em{
                    opacity: 0;
                    transition: ease .3s;
                    position: absolute;
                    top: 25/@maxw;
                    display: inline-block;
                    width: 7/@maxw;
                    height: 21/@maxw;
                    background:linear-gradient(150deg, #174994 0% ,#387DE2 100%);
                }
                span{
                    display: inline-block;
                    line-height: 1.3;
                    padding-top: 5/@maxw;
                    // word-break: break-all;
                    // overflow:hidden;image.png
                    // white-space: nowrap;
                    // text-overflow: ellipsis;
                    // -o-text-overflow:ellipsis;
                    width: 88%;
                    margin-left: 22/@maxw;
                    &:hover{
                    color: #174994 !important;
                    }
                }
                i{
                    float: right;
                    font-style: normal;
                    transition: all .3s;
                    display: block;
                    width: 11/@maxw;
                    height: 19/@maxw;
                    margin-top: 26/@maxw;
                    background-image: url("../../../assets/left_icon.png");
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
                .towmenus{
                    // margin-top: -40/@maxw;
                    margin-top: -20/@maxw;
                    font-size: 18/@maxw;
                    width: 100%;
                    padding: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: ease .3s;
                    >#section{
                        padding: 0;
                        margin: 0;
                        width: 100%;
                        height: 28/@maxw;// ||35
                        display: flex;
                        justify-content: right;
                        align-items: center;
                        &:hover{color: #174994 !important;}
                    }
                }
                
            }
            >nav:hover{
                em{
                    opacity: 1 !important;
                }
                
            }
            >nav:last-child{
                background-image: url("");
            }
        }
        .leftNavlist::after{
            position: absolute;
            bottom: 0;
            right: 0;
            width: 229/@maxw;
            height: 238/@maxw;
            content: '';
            background: url('../../../assets/enarticehua.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: -111;
        }
        .rightRouterview{
            margin-top: 55/@maxw;
            width: 1332/@maxw;
            transform: translateY(800px);
            opacity: 0;
            transition: ease .6s;
            // 给富文本里面的a链接设置hover效果
            /deep/ a:hover{ 
              text-decoration: revert !important;
            } 
            /deep/ img{
              max-width: 100%;
            }
            /deep/ video{
              max-width: 100%;
              object-fit: cover;
            }
        }
    }
}
@media screen and (max-width:750px) {
  @maxw:50rem;
  .enarticlebox{
    width: 750/@maxw;
    .artheader{
        height: 400/@maxw;
        .bottomback{
            height: 76/@maxw;
            .centerback{
                width: 1778/@maxw;
                height: 100%;
                .lfettitle{min-width: 200/@maxw;font-size: 20/@maxw;width: auto;padding: 0 10/@maxw;}
                .righttext{
                    font-size: 19/@maxw;
                    margin-right: 46/@maxw;
                }       
            }
        }
    }
    .section{
        // padding: 0 70/@maxw 295/@maxw 71/@maxw;
        .leftNavlist{display: none;}
        .rightRouterview{
            margin-top: 55/@maxw;
            width: 1332/@maxw;
            // 给富文本里面的a链接设置hover效果
            /deep/ a:hover{ 
              text-decoration: revert !important;
            } 
            /deep/ img{
              max-width: 100%;
            }
            /deep/ video{
              max-width: 100%;
              object-fit: cover;
            }
        }
    }
}
}
</style>